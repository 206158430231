// IMPORTS
import {
    Button,
    Chip,
    Grid,
    Pagination,
    TableRow,
    Tooltip,
    IconButton,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Paper from '../../../global/Paper';
import TableSearch from '../../../global/tableComponents/TableSearch';
import DeletePaymentRunDialog from './DeletePaymentRunDialog';
import LoadingButton from '@mui/lab/LoadingButton';
// LOGIC
import DownloadABA from '../logic/DownloadABA';
import HandleGenerateABA from '../logic/HandleGenerateABA';
import { PaginateInvoices } from '../logic/InvoiceSorting';
import {
    CurrencyFormatter,
    TimestampFormatter
} from '../../../global/logic/Formatters';
import RegeneratePaymentRemittance from '../logic/RegeneratePaymentRemittance';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// INTERFACES
import { PaymentRun } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const sort = ['id', 'desc'];

const CreditorPayments = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [paymentRuns, setPaymentRuns] = useState<PaymentRun[]>([]);
    const [paginatedInvoices, setPaginatedInvoices] = useState<any[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedPaymentRun, setSelectedPaymentRun] =
        useState<PaymentRun>(null);

    const payrunColumns = [
        { id: 0, label: 'Payment Run ID' },
        { id: 1, label: 'Created Date' },
        { id: 2, label: 'Invoice Count' },
        { id: 3, label: '' }
        // {
        //     id: 4,
        //     label: ''
        // }
    ];

    const reconciliationColumns = [
        { id: 0, label: 'Invoice ID' },
        { id: 1, label: 'Document Reference' },
        { id: 2, label: 'Creditor' },
        { id: 3, label: 'Amount Paid' }
    ];

    // Select the first payment run once payment runs have been retrieved
    useEffect(() => {
        if (paymentRuns?.length > 0) {
            setLoading(false);
            setSelectedPaymentRun(paymentRuns[0]);
        }
    }, [paymentRuns]);

    // Paginate the payrun's invoices
    useEffect(() => {
        if (selectedPaymentRun?.Invoices?.length > 0) {
            setCurrentPage(1);
            PaginateInvoices(
                selectedPaymentRun?.Invoices,
                1,
                setPageCount,
                setPaginatedInvoices
            );
        }
    }, [selectedPaymentRun]);

    /**
     * handlePageChange
     * Change the page of results
     * @author Estienne
     * @param event the page change event
     * @param value the new page number
     */
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
        PaginateInvoices(
            selectedPaymentRun?.Invoices,
            value,
            setPageCount,
            setPaginatedInvoices
        );
    };

    const getBankRecId = (selectedPayrun) => {
        let bankRecId = null;

        let paymentHistory = selectedPayrun?.Invoices[0]?.paymentHistory;

        paymentHistory.forEach((payment) => {
            if (payment.PaymentRunId === selectedPayrun.id) {
                bankRecId = payment.BankReconciliationId;
            }
        });

        return bankRecId;
    };

    const getBankRecDate = (selectedPayrun) => {
        let bankRecDate = null;

        let paymentHistory = selectedPayrun?.Invoices[0]?.paymentHistory;

        paymentHistory.forEach((payment) => {
            if (payment.PaymentRunId === selectedPayrun.id) {
                bankRecDate = payment.bankRecDate;
            }
        });

        return bankRecDate;
    };

    const checkBankRecDataExists = (selectedPayrun, type) => {
        let bankRecId = null;
        let bankRecDate = null;

        let paymentHistory = selectedPayrun?.Invoices[0]?.paymentHistory;

        paymentHistory.forEach((payment) => {
            if (payment.PaymentRunId === selectedPayrun.id) {
                bankRecId = payment.BankReconciliationId;
                bankRecDate = payment.bankRecDate;
            }
        });

        if (type === 'date' && bankRecDate != null) {
            return true;
        } else if (type === 'id' && bankRecId != null) {
            return true;
        } else if (
            type === 'both' &&
            bankRecDate != null &&
            bankRecId != null
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getTotalLinkedPayment = (invoice, selectedPaymentRun) => {
        const paymentsLinkedToSelectedRun = invoice.paymentHistory.filter(
            (payment) =>
                payment.PaymentRunId === selectedPaymentRun.id &&
                payment.removed !== true //  payment whose linked PaymentRun has been deleted will have a removed field set to true
        );

        const totalPaymentAmount = paymentsLinkedToSelectedRun.reduce(
            (accumulator, currentPayment) =>
                accumulator + currentPayment.amount,
            0
        );

        return totalPaymentAmount;
    };

    // delete payment run
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletePaymentRunId, setDeletePaymentRunId] = useState(null);

    return (
        <>
            <TableSearch
                searchTitle="Search Payment Run"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                customLimit={15}
                handleRequestCreate={() => {
                    return '';
                }}
                route="searchPaymentRuns"
                setResultsList={setPaymentRuns}
            />

            {!loading ? (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper overflowX="auto">
                            <DataTable columns={payrunColumns}>
                                {paymentRuns.map((run) => (
                                    <TableRow
                                        selected={
                                            run.id === selectedPaymentRun?.id
                                        }
                                    >
                                        <DataCellColoured
                                            handleClick={() =>
                                                setSelectedPaymentRun(run)
                                            }
                                        >
                                            {run.id}
                                        </DataCellColoured>
                                        <DataCell>
                                            {TimestampFormatter(run.createdAt)}
                                        </DataCell>
                                        <DataCell>
                                            {run.Invoices.length}
                                        </DataCell>
                                        <DataCell>
                                            {run.reconciled ? (
                                                // <Check fontSize="small" />
                                                <Chip
                                                    variant="outlined"
                                                    color="success"
                                                    size="small"
                                                    label={'Reconciled'}
                                                />
                                            ) : (
                                                <Tooltip title="Delete Payment Run">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDeleteDialogOpen(
                                                                true
                                                            );
                                                            setDeletePaymentRunId(
                                                                run.id
                                                            );
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid item>
                            <Paper>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Typography variant="h5">{`Payment Run #${selectedPaymentRun?.id} - ${TimestampFormatter(selectedPaymentRun?.createdAt)}`}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Pagination
                                            count={pageCount}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'right'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Typography variant="body1">{`Created by ${selectedPaymentRun['User.firstName']} ${selectedPaymentRun['User.lastName']}`}</Typography>
                                {selectedPaymentRun.reconciled &&
                                checkBankRecDataExists(
                                    selectedPaymentRun,
                                    'id'
                                ) ? (
                                    <Typography variant="body1">
                                        Bank Reconciliation #
                                        {checkBankRecDataExists(
                                            selectedPaymentRun,
                                            'date'
                                        ) ? (
                                            <Typography
                                                variant="body1"
                                                onClick={() =>
                                                    window.open(
                                                        `/admin/bankReconciliation/${getBankRecDate(selectedPaymentRun)}/${getBankRecId(selectedPaymentRun)}`,
                                                        '_blank'
                                                    )
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: '#9370DB',
                                                    display: 'inline'
                                                }}
                                            >
                                                {getBankRecId(
                                                    selectedPaymentRun
                                                )}
                                            </Typography>
                                        ) : (
                                            getBankRecId(selectedPaymentRun)
                                        )}
                                    </Typography>
                                ) : null}
                                <br />
                                <DataTable columns={reconciliationColumns}>
                                    {paginatedInvoices.map((invoice) => (
                                        <TableRow>
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        `inventory/viewInvoice/${invoice.id}`
                                                    )
                                                }
                                            >
                                                {invoice.id}
                                            </DataCellColoured>
                                            <DataCell>
                                                {invoice.StockInvoiceId
                                                    ? invoice?.StockInvoice
                                                          ?.documentReference
                                                    : invoice?.CreditInvoice
                                                          ?.documentReference}
                                            </DataCell>
                                            <DataCell>
                                                {invoice?.Supplier?.name}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    getTotalLinkedPayment(
                                                        invoice,
                                                        selectedPaymentRun
                                                    )
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                                <br />
                                <Typography textAlign="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            await RegeneratePaymentRemittance({
                                                paymentRun: selectedPaymentRun
                                            });
                                        }}
                                    >
                                        Regenerate Payment Remittance
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        loading={buttonLoading}
                                        onClick={() => {
                                            HandleGenerateABA({
                                                creditors: null,
                                                payrunId: selectedPaymentRun.id,
                                                showSnackbar: showSnackbar,
                                                setButtonLoading:
                                                    setButtonLoading
                                            });
                                        }}
                                    >
                                        Regenerate ABA
                                    </LoadingButton>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <DeletePaymentRunDialog
                        deleteDialogOpen={deleteDialogOpen}
                        setDeleteDialogOpen={setDeleteDialogOpen}
                        deletePaymentRunId={deletePaymentRunId}
                    />
                </Grid>
            ) : (
                ''
            )}
        </>
    );
};

export default withSnackbar(CreditorPayments);
