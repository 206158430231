const CalculateFloorplanLiabilityBalanceSheet = (
    floorplanData: {
        endDate: string;
        data: [number, { id: number; cost: number }[], number[]][];
    }[]
) => {
    // get the list of all vehicles per site (merge periods)
    // get the list of all vehicles sold per site (merge periods)
    // calculate how much needs to be removed from the backend calculation
    let totalCostPerSite = [];
    let listOfVehicles = [];
    let listOfSold = [];
    for (let period of floorplanData) {
        for (let [index, site] of period.data.entries()) {
            if (totalCostPerSite.length === index) {
                totalCostPerSite.push(site[0]);
            } else {
                totalCostPerSite[index] += site[0];
            }

            if (listOfVehicles.length === index) {
                listOfVehicles.push(site[1]);
            } else {
                listOfVehicles[index] = [...listOfVehicles[index], ...site[1]];
            }

            if (listOfSold.length === index) {
                if (site[2] === null) {
                    listOfSold.push([]);
                } else {
                    listOfSold.push(site[2]);
                }
            } else {
                if (site[2] != null) {
                    listOfSold[index] = [...listOfSold[index], ...site[2]];
                }
            }
        }
    }

    // Now we can calculate the cost of vehicles sold that are in the list per site
    for (let [index, site] of listOfVehicles.entries()) {
        let totalCost = 0;
        for (let vehicle of site) {
            if (listOfSold[index].includes(vehicle.id)) {
                totalCost += vehicle.cost;
            }
        }
        totalCostPerSite[index] -= totalCost;
    }

    return totalCostPerSite;
};

export default CalculateFloorplanLiabilityBalanceSheet;
